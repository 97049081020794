.root {
  align-self: stretch;
  border: var(--border-decorative-secondary);
  border-left: none;
  border-radius: 0 var(--rounded-lg) var(--rounded-lg) 0;
  caret-color: transparent;
  overflow-y: scroll;
  padding: var(--spacing-8) var(--spacing-12) 0 var(--spacing-12);

  & .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    padding: 0 var(--spacing-8) var(--spacing-8) 0;

    &::-webkit-scrollbar {
      flex-grow: 0;
      overflow-x: hidden;
      position: static;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-slate-400);
      border: 3px solid transparent;
      border-radius: var(--rounded-lg);
      flex-grow: 0;
      overflow-x: hidden;
      position: static;
    }
  }
}
