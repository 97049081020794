.root {
  & input[type='radio'],
  & input[type='radio']:hover {
    accent-color: var(--l-theme-border-color);
  }

  &.block {
    align-items: center;
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-lg);
    color: var(--color-text-body);
    display: flex;
    flex: 1;
    padding: var(--spacing-3);

    /* Make the whole label clickable */
    & label {
      cursor: pointer;
      flex-grow: 1;
      font-weight: var(--font-medium);
    }

    &.disabled {
      background-color: var(--color-bg-disabled);
      cursor: not-allowed;

      & > label {
        color: var(--color-text-disabled);
        cursor: not-allowed;
      }
    }

    &:not(.checked) {
      & label {
        color: var(--color-text-heading);
      }
    }

    &.checked {
      background-color: var(--l-theme-bg-color);
      border-color: var(--l-theme-border-color);
      color: var(--l-theme-content-color);

      & label {
        color: var(--l-theme-content-color);
      }
    }
  }
}
