.root {
  align-items: center;
  border-radius: var(--rounded-md);
  display: flex;
  font-weight: var(--font-medium);
  gap: var(--spacing-2);
  padding: var(--spacing-1-half) var(--spacing-2-half);
  text-align: left;

  & > * {
    flex: 1 1 auto;
  }

  & .prefix,
  & .suffix {
    display: block;
    flex: 0 0 auto;
    font-size: var(--text-base);
    line-height: var(--icon-size-5);
    max-height: var(--icon-size-5);
    max-width: var(--icon-size-5);
    text-align: center;
    vertical-align: middle;
    width: var(--icon-size-5);
  }
}
