.root {
  align-items: flex-end;
  background: var(--color-bg-primary);
  border: 2px solid transparent;
  display: flex;
  max-width: var(--content-max-width-v2);
  width: 100%;

  & .icon {
    cursor: grab;
    margin-bottom: 9px;
    margin-right: var(--spacing-3);
  }

  & .input {
    flex-grow: 1;
  }
}

.checkbox {
  width: 100%;
}

.error {
  background: var(--color-red-50);
  border: 1px solid var(--color-danger);
  border-radius: var(--rounded);
  padding: var(--spacing-1) var(--spacing-1) var(--spacing-1) 0;
}

.placeholder {
  display: flex;

  & .input {
    background: var(--color-sky-50);
    border: 1px dashed var(--color-sky-500);
    border-radius: var(--rounded-md);

    & > * {
      opacity: 0;
    }
  }
}
