.root {
  & > ol {
    list-style: none;

    & > li {
      flex-grow: 0;

      /* Don't add a caret to the last bread crumb */
      &:not(:last-child) {
        &::after {
          color: var(--color-slate-400);
          content: '›';
          margin: 0 var(--spacing-2);
        }
      }

      /* Override existing style */
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }

  /* Add specificity to override NavItem styles */
  & .crumb.crumb {
    display: flex;

    & a {
      /* Don't add background color to bread crumb */
      background-color: inherit;
      border-radius: 0;
      color: var(--color-slate-500);
      font-weight: var(--font-medium);

      &:hover {
        background-color: inherit;
        color: var(--color-slate-700);
      }

      & > * {
        /* Remove padding from NavItemHeader */
        padding: 0;
      }
    }
  }
}

@media print {
  a[href]:after {
    content: unset;
  }
}
