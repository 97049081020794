/* Removes the extra vertical space imposed by Page */
.legacyScroll {
  padding-bottom: 0px;
}

.deleteButton {
  color: var(--color-danger);
}

.leftActions {
  display: flex;
}

.lastPublished {
  margin-left: var(--spacing-1);
}

.chips {
  display: flex;
  gap: var(--spacing-2);
}
