/* stylelint-disable no-descending-specificity */
.root {
  color: var(--color-text-body);
  display: flex;
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  overflow: hidden;

  & > ul {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;

    & > li {
      flex: 0 0 auto;
      white-space: nowrap;

      &.secondary {
        flex-grow: 1;
      }

      &:last-child {
        padding-bottom: var(--spacing-3);
      }
    }

    & .logo {
      /* Add additional spacing below, in addition to gap, which is also --spacing-2 resulting in --spacing-4 */
      margin-bottom: var(--spacing-2);
    }
  }
}

.help,
.notifications,
.avatar {
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: var(--color-primary-3);
  }

  & button {
    width: 100%;
  }
}

.help {
  /* Align help and subsequent items to bottom of the page */
  margin-top: auto;
}

.separator {
  border-top: 1px solid var(--color-decorative-secondary);
  margin-top: var(--spacing-2);
}

.popper {
  padding: 0;
}

.powered {
  font-size: var(--text-sm);
  line-height: initial;
  margin-top: var(--spacing-4);
  text-align: center;

  & img {
    height: calc(var(--text-sm) * 1.1429);
  }
}
