.card {
  --l-header-height: 121px;

  /* Add header height to top to prevent cutting 
    off sticky card with sticky header */
  top: calc(var(--l-header-height) + var(--spacing-4));
}

.description {
  margin-top: var(--spacing-4);
}
