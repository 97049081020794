.metadata {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  & .daterange {
    display: block;
  }
}

.chips {
  display: flex;
  gap: var(--spacing-2);
}

.timeRange {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-6);
}

.time {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
}

.label {
  margin-bottom: 0;

  &::after {
    content: ':';
  }
}

@media (max-width: 1200px) {
  .timeRange {
    flex-direction: column;
    gap: var(--spacing-1);
  }
}
