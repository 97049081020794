.editorWrapper {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  min-height: 6em;
  padding: var(--spacing-2);
}

.rightAlign {
  text-align: right !important;

  & > div {
    display: inline-block;
  }
}

.centerAlign {
  text-align: center !important;

  & > div {
    display: inline-block;
  }
}

.justifyAlign {
  text-align: justify !important;

  & > div {
    display: inline-block;
  }
}

.leftAlign {
  text-align: left !important;

  & > div {
    display: inline-block;
  }
}

.toolsWrapper {
  border-bottom: 1px solid var(--color-decorative-secondary);
  display: flex;
  margin-bottom: var(--spacing-2);

  & > div:nth-child(2) {
    margin-left: var(--spacing-2);
  }

  & > button {
    margin-left: auto;
  }
}

.draftWrapper {
  cursor: text;
}

.blockTypeSelect {
  --l-input-width: 100px;

  position: relative;
  /* Fix cut off element */
  width: var(--l-input-width) !important;
  z-index: 5;
}
