.root {
  background: var(--color-bg-secondary);
  border: 1px solid var(--color-slate-200);
  border-radius: var(--rounded);
  height: 320px;
  padding: var(--spacing-8);
  padding-bottom: 0;
  pointer-events: none;

  & .container {
    background: var(--color-bg-primary);
    border: 1px solid var(--color-slate-200);
    border-bottom: none;
    border-radius: var(--rounded-sm) var(--rounded-sm) 0 0;
    height: 100%;
    width: 100%;
  }

  & .spinner {
    height: 100%;
  }
}
