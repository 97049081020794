.root {
  color: var(--color-slate-500);
  line-height: var(--leading-3xl);
  padding: 0 var(--spacing-1);
  text-align: center;
  white-space: nowrap;

  /* Prefix with an em dash */
  &::before {
    content: '\2014 ';
    margin-right: var(--spacing-1);
  }

  /* Suffix with an em dash */
  &::after {
    content: '\2014';
    margin-left: var(--spacing-1);
  }
}
