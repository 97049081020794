.root {
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  & .container {
    cursor: grab;
    display: flex;
    padding: var(--spacing-5) var(--spacing-2);
    width: 100%;

    & .grip {
      color: var(--color-slate-700);
      cursor: inherit;
    }

    & .icon {
      color: var(--color-text-body);
    }

    & .content {
      align-items: center;
      display: flex;
      gap: var(--spacing-2);
      overflow: hidden;
    }

    & .label {
      color: var(--color-text-heading);
      font-weight: var(--font-semibold);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.placeholder {
  & .container {
    background: var(--color-bg-secondary);
    border: 1px dashed var(--color-decorative-secondary);
    border-radius: var(--rounded-md);
    content-visibility: hidden;
    overflow: hidden;
  }
}
